import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProductsShop, deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";

const AllProducts = () => {
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [reloadMessageOpen, setReloadMessageOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  useEffect(() => {
    if (deleteSuccess) {
      setTimeout(() => {
        setReloadMessageOpen(true);
      }, 500);
    }
  }, [deleteSuccess]);

  const navigate = useNavigate();
  const handleDelete = (id) => {
    setProductIdToDelete(id);
    setOpenConfirmationDialog(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteProduct(productIdToDelete));
    setProductIdToDelete(null);
    setOpenConfirmationDialog(false);
    setDeleteSuccess(true);
    navigate("/dashboard-products");
    window.location.reload();
  };

  const handleCancelDelete = () => {
    setProductIdToDelete(null);
    setOpenConfirmationDialog(false);
  };

  const handleCloseReloadMessage = () => {
    setReloadMessageOpen(false);
  };

  const handleEdit = (id) => {};

  const columns = [
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Name", minWidth: 180, flex: 1.4 },
    { field: "price", headerName: "Price", minWidth: 100, flex: 0.6 },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/product/${params.id}`}>
          <Button>
            <AiOutlineEye size={20} />
          </Button>
        </Link>
      ),
    },
    {
      field: "Edit",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        // Inside the renderCell method for the Edit button
        <Link
          to={`/update-product/${params.id}?name=${params.name}&price=${params.price}&stock=${params.Stock}&sold=${params.sold}`}
        >
          <Button>
            <AiOutlineEdit size={20} />
          </Button>
        </Link>
      ),
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleDelete(params.id)}>
          <AiOutlineDelete size={20} />
        </Button>
      ),
    },
  ];

  const rows = products
  ? products
      .map((item) => ({
        id: item._id,
        name: item.name,
        price: "AED " + item.discountPrice, // Append "AED " to the price
        Stock: item.stock,
        sold: item?.sold_out,
      }))
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) // Sort rows by product ID
  : [];


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          <Dialog
            open={openConfirmationDialog}
            onClose={handleCancelDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this product?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDelete} color="primary">
                No
              </Button>
              <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={reloadMessageOpen}
            autoHideDuration={4000}
            onClose={handleCloseReloadMessage}
          >
            <MuiAlert
              onClose={handleCloseReloadMessage}
              severity="success"
              elevation={6}
              variant="filled"
            >
              Product has been successfully deleted. Reloading...
              {/* Navigate to dashboard page */}
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="inherit">
                  Go to Dashboard
                </Button>
              </Link>
            </MuiAlert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default AllProducts;
