import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FaFacebook, FaTiktok, FaInstagram } from "react-icons/fa";
import logo from "./aarya.png";
 
const Navbar = () => {
  const [nav, setNav] = useState(false);
  const location = useLocation();
 
  const handleNav = () => {
    setNav(!nav);
  };
 
  const handleNavItemClick = () => {
    setNav(false);
    smoothScrollToTop();
  };
 
  const smoothScrollToTop = () => {
    let currentPosition = window.pageYOffset;
 
    const scroll = () => {
      currentPosition -= 20;
      window.scrollTo(0, currentPosition);
 
      if (currentPosition > 0) {
        window.requestAnimationFrame(scroll);
      }
    };
 
    scroll();
  };
 
  const navItems = [
    { id: 1, text: "Home", link: "/" },
    { id: 2, text: "About Us", link: "/aboutus" },
    { id: 3, text: "Shop Now", link: "/shop" },
  ];

  return (
    <div className="font-[sans-serif] relative flex w-[100%] h-[20%] items-center justify-between bg-blue-50 rounded-b-full py-1 text-black shadow-lg hover:text-neutral-700 focus:text-neutral-700 md:flex-wrap md:justify-start">
      {/* Logo */}
      <Link to="/" className="ml-10 sm:ml-14 mr-1 mt-1 mb-1">
        <img src={logo} alt="Logo" className="w-auto h-12 sm:w-auto sm:h-14" />
      </Link>

      {/* Desktop Navigation */}
      <ul className="hidden sm:flex items-center justify-center flex-grow">
        {navItems.map((item) => (
          <li
            key={item.id}
            className={`p-4 hover:bg-[#5e71af] rounded-xl m-2 cursor-pointer duration-300 hover:text-black font-bold ${
              location.pathname === item.link
                ? "bg-[#b367a7] text-black font-bold"
                : ""
            }`}
          >
            <NavLink
              to={item.link}
              className="px-2 py-1 rounded-md text-xl"
              onClick={handleNavItemClick}
            >
              {item.text}
            </NavLink>
          </li>
        ))}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="block sm:hidden mr-7">
        {" "}
        {/* Added mr-2 for gap on the right */}
        {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? "fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-white ease-in-out duration-500 z-50"
            : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]"
        }
      >
        {/* Mobile Logo */}
        <Link to="/">
        <img src={logo} alt="Logo" className="w-12 h-auto m-4" />
        </Link>

        {/* Mobile Navigation Items */}
        {navItems.map((item) => (
          <li
            key={item.id}
            className={`p-4 border-b hover:bg-purple-300 duration-300 hover:text-black font-bold cursor-pointer border-gray-600 ${
              location.pathname === item.link ? "bg-purple-800 text-white" : ""
            }`}
            onClick={handleNavItemClick}
          >
            <Link to={item.link} className="block w-full h-full">
              {item.text}
            </Link>
          </li>
        ))}
      </ul>

      {/* Social Icons (hidden on mobile) */}
      <div className="hidden md:flex justify-end md:mr-8">
        <a
          href="https://www.facebook.com/profile.php?id=61550883064430"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook className="text-xl mx-2" />
        </a>
        <a
          href="https://www.tiktok.com/@aaryamobiletrading01?_t=8kFpICnMJes&_r=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTiktok className="text-xl mx-2" />
        </a>
        <a
          href="https://www.instagram.com/aaryacomputertradingllc/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="text-xl mx-2" />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
