import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProductsShop, deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useNavigate } from "react-router-dom";
import { updateProduct } from "../../redux/actions/product";

const AllProducts = () => {
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [reloadMessageOpen, setReloadMessageOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  useEffect(() => {
    if (deleteSuccess) {
      setTimeout(() => {
        setReloadMessageOpen(true);
      }, 500);
    }
  }, [deleteSuccess]);

  const navigate = useNavigate();
  const handleDelete = (id) => {
    setProductIdToDelete(id);
    setOpenConfirmationDialog(true);
  };


  const handleConfirmDelete = async (e) => {
    e.preventDefault();
    
  const startDate = "1999-09-09T00:00:00.000+00:00";
  const endDate = "1999-09-09T00:00:00.000+00:00";
  
  try {
    const newForm = new FormData();

    newForm.append("startDate", startDate);
    newForm.append("endDate", endDate);

    // Dispatch updateProduct action with FormData object
    await dispatch(updateProduct(productIdToDelete, newForm));
    setOpenConfirmationDialog(false);
    navigate("/dashboard-Events");
    window.location.reload();
  } catch (error) {
    
  } finally {
  }
};

  const handleCancelDelete = () => {
    setProductIdToDelete(null);
    setOpenConfirmationDialog(false);
  };

  const handleCloseReloadMessage = () => {
    setReloadMessageOpen(false);
  };

  const handleEdit = (id) => {};

  const columns = [
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Name", minWidth: 180, flex: 1.4 },
    { field: "StartDate", headerName: "StartDate", minWidth: 180, flex: 1},
    {
      field: "EndDate",
      headerName: "EndDate",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/events/`}>
          <Button>
            <AiOutlineEye size={20} />
          </Button>
        </Link>
      ),
    },
    
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleDelete(params.id)}>
          <AiOutlineDelete size={20} />
        </Button>
      ),
    },
  ];

  
  const filteredProducts = products
    ? products.filter((product) => {
        const today = new Date();
        const endDate = new Date(product.endDate);
        return product.endDate && endDate >= today;
      })
    : [];

    
  const rows = filteredProducts
  ? filteredProducts
      .map((item) => ({
        id: item._id,
        name: item.name,
        StartDate: new Date(item.startDate).toLocaleDateString(),
        EndDate: new Date(item.endDate).toLocaleDateString(),
      }))
      .sort((a, b) => (a.EndDate < b.EndDate ? 1 : -1)) // Sort rows by product ID
  : [];


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full mx-8 pt-1 mt-10 bg-white">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          <Dialog
            open={openConfirmationDialog}
            onClose={handleCancelDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this product?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDelete} color="primary">
                No
              </Button>
              <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={reloadMessageOpen}
            autoHideDuration={4000}
            onClose={handleCloseReloadMessage}
          >
            <MuiAlert
              onClose={handleCloseReloadMessage}
              severity="success"
              elevation={6}
              variant="filled"
            >
              Product has been successfully deleted. Reloading...
              {/* Navigate to dashboard page */}
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="inherit">
                  Go to Dashboard
                </Button>
              </Link>
            </MuiAlert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default AllProducts;
