import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { navItems } from "../../static/data";
import { Link } from "react-router-dom";

const mytextStyle = {
  color: 'black',
  fontSize: '18px',
  fontWeight: '600',
  fontFamily: 'Arial, Helvetica, sans-serif',
};

const DropDown = ({ categoriesData, setDropDown, setOpen, active }) => {
  
  const navigate = useNavigate();
  const [expandedCategory, setExpandedCategory] = useState(null);

  const handleToggleClick = (e, categoryId) => {
    e.stopPropagation();
    if (expandedCategory === categoryId) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(categoryId);
    }
  };

  const handleMainCategoryClick = (category) => {
    setExpandedCategory(category.id);
    navigate(`/products?category=${encodeURIComponent(category.title)}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setDropDown(false);
    setOpen(false);
  };

  const handleCategoryClick = (category, subcategory) => {
    let url = `/products?category=${encodeURIComponent(category.title)}`;

    if (subcategory) {
      url += `&subcategory=${encodeURIComponent(subcategory.title)}`;
    }

    navigate(url);
     window.scrollTo({ top: 0, behavior: "smooth" });
    setDropDown(false);
    setOpen(false);
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setOpen(false);
  };

  return (
    <div
      className="pb-2 w-full md:w-[270px] bg-[#fff] absolute md:relative z-30 rounded-b-md shadow-sm"
      onClick={() => setExpandedCategory(null)}
    >
      {categoriesData &&
        categoriesData.map((category) => (
          <div key={category.id}>
            <div
              className={`${styles.noramlFlex}`}
              onClick={() => handleMainCategoryClick(category)}
            >
              <h3
                className="m-3 cursor-pointer select-none"
                style={{ fontSize: "18px" }}
              >
                {category.title}
              </h3>
               {/* Navbar */}
              <span
                className="ml-auto mr-3 cursor-pointer select-none text-[30px]"
                onClick={(e) => handleToggleClick(e, category.id)}
              >
                {expandedCategory === category.id ? "-" : "+"}
              </span>
            </div>
            {expandedCategory === category.id &&
              category.subcategories &&
              category.subcategories.length > 0 && (
                <div>
                  {category.subcategories.map((subcategory, subIndex) => (
                    <div
                      key={subIndex}
                      className={`${styles.noramlFlex} ml-6`}
                      onClick={() => handleCategoryClick(category, subcategory)}
                    >
                      
                      <h3 className="m-3 cursor-pointer select-none">
                        {subcategory.title}
                      </h3>
                    </div>
                  ))}
                </div>
              )}
          </div>
        ))}
  <div className="min-[800px]:hidden mt-3">
        <div className={`block 800px:${styles.noramlFlex}`}>
      {navItems &&
        navItems.map((i, index) => (
          <div style={mytextStyle} className="flex" key={index}>
            {" "}
            {/* Added key prop */}
            <Link
            onClick={handleClick}
              to={i.url}
              className={`${
                active === index + 1
                  ? "text-red-600"
                  : "text-black 800px:text-[#000]"
              } pb-[30px] 800px:pb-0 px-5 cursor-pointer}`}
            >
              {i.title}
            </Link>
          </div>
        ))}
    </div>
    </div>
    </div>
  );
};

export default DropDown;
