import React from "react";
import { RxCross1 } from "react-icons/rx";
import { IoBagHandleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../redux/actions/cart";
import CartSingle from "./CartSingle";
import styles from "../../styles/styles";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";

const Cart = ({ onClose, setOpenCart }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce((acc, item) => {
    console.log("Calculating totalPrice...");
    return acc + item.qty * item.discountPrice;
  }, 0);

  return (
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-40">
      <div className="fixed top-0 right-0 h-full w-[90%] min-[400px]:w-[80%] min-[600px]:w-[65%] min-[800px]:w-[55%] min-[1000px]:w-[40%]  min-[1200px]:w-[35%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        <div>
          <div className="flex w-full justify-end pt-5 pr-5">
            <RxCross1
              size={25}
              className="cursor-pointer"
              onClick={() => {setOpenCart(false);
              }}
            />
          </div>
          {/* Item length */}
          <div className="flex items-center p-4">
            <IoBagHandleOutline size={25} />
            <h5 className="pl-2 text-[20px] font-[500]">
              {cart && cart.length} items
            </h5>
          </div>

          {/* cart Single Items */}
          <div className="w-full border-t">
            {cart && cart.length === 0 ? (
              <div className="p-4 text-center text-[16px] font-[600]">Cart is empty!</div>
            ) : (
              cart.map((item, index) => (
                <CartSingle
                  key={index}
                  data={item}
                  removeFromCartHandler={removeFromCartHandler}
                  
                />
              ))
            )}
          </div>
        </div>

        <div className="px-5 mb-2">
          {/* checkout buttons */}
          <Link to="/checkout">
            <div className="mb-2 flex items-center justify-center w-[100%] bg-fuchsia-700 hover:bg-purple-700 px-2 py-2 rounded-md">
              <h1 className="text-[#fff] text-[14px] sm:text-[18px] text-center font-[600]">
                Checkout Now (AED  {totalPrice.toFixed(2)})
              </h1>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cart;
