import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { updateProduct } from "../../redux/actions/product";
import { toast } from "react-toastify";
import { AiOutlinePlusCircle } from "react-icons/ai"; // Import AiOutlinePlusCircle
import { categoriesData } from "../../static/data";
import axios from "axios";
import { server } from "../../server";

const UpdateProduct = () => {
  const { id } = useParams();
  const { seller } = useSelector((state) => state.seller);
  const { products, success, error } = useSelector((state) => {
    console.log(state);
    return state.products;
  });
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  // Initialize state values based on the data you want to bind
  const [name, setName] = useState(""); // Define name state
  const [description, setDescription] = useState(""); // Define description state
  const [description1, setDescription1] = useState(""); // Define description state
  const [category, setCategory] = useState(""); // Define category state
  const [subcategory, setSubcategory] = useState(""); // Define subcategory state
  const [tags, setTags] = useState(""); // Define tags state
  const [originalPrice, setOriginalPrice] = useState(""); // Define originalPrice state
  const [discountPrice, setDiscountPrice] = useState(""); // Define discountPrice state
  const [stock, setStock] = useState(""); // Define stock state
  const [images, setImages] = useState([]); // Define images state
  const [imagesicon, setImagesicon] = useState([]);
  const [updateSuccess, setUpdateSuccess] = useState(false); // State variable to track update success
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  const getProduct = async () => {
    const response = await axios.get(`${server}/product/get-product/${id}`);
    const data = response.data;
    console.log("Dataaaaaa", data);
    if (response) {
      setProduct(data.products);
    } else {
      console.error("Error");
    }
  };
  useEffect(() => {
    console.log("Producctt Fetcheddd", id);
    getProduct();
  }, []);

  useEffect(() => {
    console.log("Productssss", product);
    if (product) {
      setName(product.name);
      setDescription(product.description);
      setDescription1(product.description1);
      setCategory(product.category);
      setSubcategory(product.subcategory);
      setTags(product.tags);
      setOriginalPrice(product.originalPrice);
      setDiscountPrice(product.discountPrice);
      setStock(product.stock);
      setImagesicon(product.images);
      setImages(product.images);
    }
  }, [product]);
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product updated successfully!");
      setUpdateSuccess(true); // Set update success to true
    }
  }, [error, success]);

  // Function to handle change in tags
  const handleTagsChange = (e) => {
    setTags(e.target.value); // Update the tags state with the selected quality
  };

  // Function to handle image change
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const totalImages = images.length + files.length;
    setImages(files);
  
    if (totalImages <= 6) {
      files.forEach((file) => {
        const imageUrl = URL.createObjectURL(file);
        setImagesicon((old) => [
          ...old,
          { url: imageUrl, file }, // Include file object for each image
        ]);
      });
    } else {
      toast.error("You can only upload up to Six images.");
    }
  };
  
  const handleRemoveImage = async (index, e) => {
    e.preventDefault();
    setImages(oldImages => oldImages.filter((_, idx) => idx !== index));
    setImagesicon(oldIcons => oldIcons.filter((_, idx) => idx !== index));
    setRemovedImages(oldRemoved => [...oldRemoved, images[index].public_id]);
  };
  
  

  // Function to handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();

  // Show processing message
  setIsUpdating(true);

  try {
    const newForm = new FormData();

    // Append all form data to FormData object
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("description1", description1);
    newForm.append("category", category);
    newForm.append("subcategory", subcategory);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);

    // Append updated images
    images.forEach((image, index) => {
      newForm.append("images", image);
    });

     // Append removed image indexes
     removedImages.forEach((image, index) => {
      newForm.append("removedImages", image); // Assuming images are objects with public_id property
    });


    // Dispatch updateProduct action with FormData object
    await dispatch(updateProduct(id, newForm));

    // Show success message after successful update
    toast.success("Product updated successfully!");
    navigate("/dashboard-products");
    window.location.reload();
    setUpdateSuccess(true); // Set update success to true
  } catch (error) {
    // Show error message if update fails
    toast.error("Failed to update product. Please try again later.");
  } finally {
    // Reset the updating state after navigating or updating fails
    setIsUpdating(false);
  }
};


  return (
    <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Update Product</h5>
      {updateSuccess && <p>Your product has been updated.</p>}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name} // Bind value to state
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            // placeholder="Enter your product name..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description} // Bind value to state
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            // placeholder="Enter your product description..."
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Description Details <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description1} // Bind value to state
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription1(e.target.value)}
            // placeholder="Enter your product description details..."
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5 px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Choose a category</option>
            {categoriesData.map((cat) => (
              <option value={cat.title} key={cat.id}>
                {cat.title}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Subcategory <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={subcategory}
            onChange={(e) => setSubcategory(e.target.value)}
          >
            <option value="">Choose a subcategory</option>
            {categoriesData
              .find((cat) => cat.title === category)
              ?.subcategories.map((subcat) => (
                <option value={subcat.title} key={subcat.id}>
                  {subcat.title}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2">Tags</label>
          <select
            name="tags"
            value={tags}
            onChange={handleTagsChange}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="" disabled>
              Select quality...
            </option>
            <option value="Good">Good</option>
            <option value="Average">Average</option>
            <option value="Excellent">Excellent</option>
            <option value="Brand-New">Brand New</option>
          </select>
        </div>

        <br />
        <div>
          <label className="pb-2">Original Price</label>
          <input
            type="number"
            name="price"
            value={originalPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder="Enter your product price..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="price"
            value={discountPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDiscountPrice(e.target.value)}
            placeholder="Enter your product price with discount..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Product Stock <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="price"
            value={stock}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStock(e.target.value)}
            placeholder="Enter your product stock..."
          />
        </div>
        <br />

        {/* Rest of your form fields */}
        {/* Your existing form fields */}
        <br />
        <div>
          <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {imagesicon &&
                imagesicon.map((img, index) => (
                  <div key={index} className="relative">
                    <img
                      src={img.url}
                      alt=""
                      className="h-[120px] w-[120px] object-cover m-2"
                    />
                    <button
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 cursor-pointer"
                    onClick={(e) => handleRemoveImage(index, e)} // Pass the event parameter
                  >
                    X
                  </button>
                  </div>
                ))}
          </div>
          <br />
          <div>
            <input
              type="submit"
              value={isUpdating ? "Updating..." : "Update"}
              disabled={isUpdating} 
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateProduct;
