import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dm from "./dm.jpg";
import em from "./em.jpg";
import ap from "./ap.jpg";
import bs from "./bs.jpg";
import web from "./web.jpeg";
import ecom from "./ecom.webp";
import gd from "./gd.webp";
import dr from "./dr.webp";
import { Link } from "react-router-dom";
import ScrollToTopButton from "../scroll/scrollmain";
import ChatBox from "../chat/whatsappmain";
import './services.css'



const Services = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // Adjusted breakpoint for tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Adjusted breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleExpand = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  const truncateContent = (content, index) => {
    const words = content.split(" ");
    const wordLimit = 15; // Define the word limit here

    if (words.length > wordLimit) {
      const truncatedContent = words.slice(0, wordLimit).join(" ") + "...";
      return (
        <div>
          {truncatedContent}
          <button
            className="text-blue-500 font-semibold mt-2 focus:outline-none"
            onClick={() => toggleExpand(index)}
          >
            {/* See More */}
          </button>
        </div>
      );
    } else {
      return content;
    }
  };
  const serviceCardStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };

  return (
    <>
     <div className=" font-[sans-serif] py-20  animate-fade-up animate-once">
        <div className="max-w-7xl mx-auto px-4">
          <div className="line-div">
          <h2 className="text-3xl xl:text-5xl font-bold text-center mb-3 ">
            Our <span className="text-fuchsia-800 ">Services</span>
          </h2>
              <hr/>
              <hr/>
          </div>
        
          <div class="grid lg:grid-cols-2 md:grid-cols-2 gap-12 max-md:max-w-lg mx-auto">
          <div className="bg-white rounded-lg shadow-md overflow-hidden hover:text-blue-800 hover:font-medium	hover:shadow-xl transition duration-300">
                <img
                  src={dr}
                  alt="Device Supply and Repair"
                  className="w-full h-[170px] object-cover mt-2 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">
                  Trading of Electronics goods
                  </h3>
                </div>
              </div>
            {/* Service Card 1: Web Designing and Hosting */}
              <div className="bg-white rounded-lg shadow-md overflow-hidden hover:text-blue-800 hover:font-medium	hover:shadow-xl transition duration-300">
                <img
                  src={web}
                  alt="Web Design"
                  className="w-full h-[170px] object-cover mt-2 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">
                    Web Designing and Hosting
                  </h3>
                </div>
              </div>
          
            {/* Service Card 2: Ecommerce Management */}
           
              <div className="bg-white rounded-lg shadow-md overflow-hidden hover:text-blue-800 hover:font-medium	hover:shadow-xl transition duration-300">
                <img
                  src={ecom}
                  alt="Digital Marketing"
                  className="w-full h-[170px] object-cover mt-2 z-40 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">
                    Ecommerce Management
                  </h3>
                </div>
              </div>
         
            {/* Service Card 3: Application Development */}
     
              <div className="bg-white rounded-lg shadow-md overflow-hidden hover:text-blue-800 hover:font-medium	hover:shadow-xl transition duration-300">
                <img
                  src={ap}
                  alt="Ecommerce Management"
                  className="w-full h-[170px] object-cover mt-2 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">
                    Application Development
                  </h3>
                </div>
              </div>
           
            {/* Service Card 4: Cloud Space Setup */}
            
              <div className="bg-white rounded-lg shadow-md overflow-hidden hover:text-blue-800 hover:font-medium	hover:shadow-xl transition duration-300">
                <img
                  src={em}
                  alt="Cloud Space Setup"
                  className="w-full h-[170px] object-cover mt-2 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">
                    Cloud Space Setup
                  </h3>
                </div>
              </div>
        
            {/* Service Card 5: Business Email Setup */}
              <div className="bg-white rounded-lg shadow-md overflow-hidden hover:text-blue-800 hover:font-medium	hover:shadow-xl transition duration-300">
                <img
                  src={bs}
                  alt="Business Email Setup"
                  className="w-full h-[170px] object-cover mt-2 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">
                    Business Email Setup
                  </h3>
                </div>
              </div>
            {/* Service Card 6: Digital Marketing and Branding */}

              <div className="bg-white rounded-lg shadow-md overflow-hidden hover:text-blue-800 hover:font-medium	hover:shadow-xl transition duration-300">
                <img
                  src={dm}
                  alt="Digital Marketing and Branding"
                  className="w-full h-[170px] object-cover mt-2 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">
                    Digital Marketing and Branding
                  </h3>
                </div>
              </div>
      
              <div className="bg-white rounded-lg shadow-md overflow-hidden hover:text-blue-800 hover:font-medium	hover:shadow-xl transition duration-300">
                <img
                  src={gd}
                  alt="Graphics Design"
                  className="w-full h-[170px] object-cover mt-2 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">
                    Graphics Design
                  </h3>
                </div>
              </div>
          </div>   
        </div>
        <ChatBox />
        <ScrollToTopButton />
      </div>
      <style>
        {`
          @media (max-width: 467px) {
            .slick-slide {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

export default Services;
