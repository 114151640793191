import React, { useState, useEffect } from "react";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import Cart from "../../cart/Cart";

const ProductCard = ({ data, isEvent, stock, tags }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist, data._id]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id, stock) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
      setOpenCart(true);
    } else {
      if (stock < 1) {
        toast.error("Product out of stock!");
      } else {
        const requestedQty = 1; // Change this value based on the user's input
        if (requestedQty > stock) {
          toast.error("Requested quantity exceeds available stock!");
        } else {
          const cartData = { ...data, qty: requestedQty };
          dispatch(addTocart(cartData));
          toast.success("Item added to cart successfully!");
          setOpenCart(true); // Open cart section after adding the item
        }
      }
    }
  };

  const closeCart = () => {
    setOpenCart(false);
  };

  const toggleCart = () => {
    setOpenCart(!openCart);
  };

  return (
    <>
      <div className="w-full bg-white rounded-lg shadow-xl p-5 relative cursor-pointer">
        <Link
          to={`${`/product/${data._id}`
          }`}
        >
          <img
            src={`${data.images && data.images[0]?.url}`}
            alt=""
            className="w-full h-[170px] object-contain pb-5"
          />
        </Link>
        <Link
          to={`${`/product/${data._id}`
          }`}
        >
          <h4 className="pb-3 text-[16px] font-[600]">
            {data.name.length > 40 ? data.name.slice(0, 80) + "..." : data.name}
          </h4>

          <div className="flex">
            <Ratings rating={data?.ratings} />
          </div>

          <div className="py-2 flex items-center justify-between ">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
              AED {" "}{data.originalPrice === 0
                  ? data.originalPrice.toFixed(2)
                  : data.discountPrice.toFixed(2)}
              </h5>
              <h4 className={`${styles.price}`}>
                AED {" "}{data.originalPrice.toFixed(2) ? data.originalPrice.toFixed(2) : null}
              </h4>
            </div>
          </div>
          <p className="font-semibold text-[16px] font-[700] text-fuchsia-800 mt-4">
            In stock: {data.stock}
            </p>
          {/* Display selected tags (quality) */}
          <div className="py-2 flex">
            <p className="font-[500]">Quality:&nbsp;</p>
              {data.tags}
          </div>
        </Link>

        {/* side options */}
        <div>
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer absolute right-2 top-14"
            onClick={() => setOpen(!open)}
            color="#333"
            title="Quick view"
          />
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer absolute right-2 top-24"
            onClick={() => addToCartHandler(data._id, data.stock)}
            color="#444"
            title="Add to cart"
          />

          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
      </div>
      {/* Cart Section */}
      {openCart ? <Cart setOpenCart={setOpenCart} /> : null}
    </>
  );
};

export default ProductCard;
