import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import styles from "../../../styles/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import offer from "./offer.jpg";
import offer2 from "./offer2.jpg";
import offer3 from "./offer3.jpeg";
const Hero = ({ categoriesData }) => {
  const navigate = useNavigate();
  const [expandedCategory, setExpandedCategory] = useState(null);

  const handleToggleClick = (e, categoryId) => {
    e.stopPropagation();
    if (expandedCategory === categoryId) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(categoryId);
    }
  };

  const handleMainCategoryClick = (category) => {
    setExpandedCategory(category.id);
    navigate(`/products?category=${encodeURIComponent(category.title)}`);
  };

  const handleCategoryClick = (category, subcategory) => {
    let url = `/products?category=${encodeURIComponent(category.title)}`;

    if (subcategory) {
      url += `&subcategory=${encodeURIComponent(subcategory.title)}`;
    }

    navigate(url);
  };

  // Offer Images
  // Offer Images
  const offerImages = [
    {
      id: 1,
      imageUrl: offer, // Use the imported offer image directly
      altText: "Offer 1",
      link: "/products", // Destination link for Offer 1
    },
    {
      id: 2,
      imageUrl: offer2, // Use the imported offer image directly
      altText: "Offer 2",
      link: "/products", // Destination link for Offer 2
    },
    {
      id: 3,
      imageUrl: offer3, // Use the imported offer image directly
      altText: "Offer 3",
      link: "/products", // Destination link for Offer 3
    },
  ];

  // Settings for the slider
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="flex justify-center gap-4 bg-slate-100">
      </div>
      {/* Offer Section with Slider */}
      <div className="mt-8 mb-6 mx-auto max-w-[90%] bg-white rounded-md relative">
        {/* Added mx-auto to center the container horizontally */}
        <Slider {...sliderSettings}>
          {offerImages.map((offer) => (
            <a key={offer.id} href={offer.link}>
              <div key={offer.id}>
                <img
                  src={offer.imageUrl}
                  alt={offer.altText}
                  className="w-full max-h-[200px] sm:max-h-[250px] md:max-h-[300px] lg:max-h-[300px]  p-1" // Responsive height based on screen size
                />
              </div>
            </a>
          ))}
        </Slider>
        {/* Hide slider icon on mobile screens */}
        <style jsx>{`
          @media (max-width: 800px) {
            .slick-next {
              display: none !important;
            }
            .slick-prev {
              display: none !important;
            }
          }
        `}</style>
      </div>
    </div>
  );
};

export default Hero;
