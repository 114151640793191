import axios from "axios";
import { server } from "../../server";

// create product

export const createProduct =
  (
    name,
    description,
    category,
    subcategory,
    tags,
    originalPrice,
    discountPrice,
    stock,
    shopId,
    images
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "productCreateRequest",
      });

      const { data } = await axios.post(
        `${server}/product/create-product`,
        name,
        description,
        category,
        subcategory,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId,
        images
      );
      dispatch({
        type: "productCreateSuccess",
        payload: data.product,
      });
    } catch (error) {
      dispatch({
        type: "productCreateFail",
        payload: error.response.data.message,
      });
    }
  };
// Fetch a single product by ID
export const getProductById = (productId) => async (dispatch) => {
  try {
    // Dispatch an action to indicate the start of the request
    dispatch({ type: "GET_PRODUCT_BY_ID_REQUEST" });

    // Make the API request to fetch the product
    const response = await axios.get(
      `${server}product/get-product/${productId}`
    ); // Adjust the API endpoint as needed

    // Dispatch an action with the fetched product data
    dispatch({
      type: "GET_PRODUCT_BY_ID_SUCCESS",
      payload: response.data.product, // Assuming the product data is available under 'product' key in the response
    });
  } catch (error) {
    // Dispatch an action if an error occurs
    dispatch({
      type: "GET_PRODUCT_BY_ID_FAILURE",
      payload: error.response.data.message, // Assuming the error message is available in the response
    });
  }
};


// Update product details
export const updateProduct = (productId, formData) => async (dispatch) => {
  try {
    dispatch({ type: "updateProductRequest" });
 
    const { data } = await axios.put(
      `${server}/product/update-product/${productId}`, 
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Important to set the content type for file uploads
        },
      }
    );
    dispatch({
      type: "updateProductSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "updateProductFailed",
      payload: error.response.data.message,
    });
  }
};

export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteProductRequest",
    });

    const { data } = await axios.delete(
      `${server}/product/delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};

// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });

    const { data } = await axios.get(`${server}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};
