import { React, useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Whatsapp from "../Whatsapp/whatsapp";

const ShopLogin = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(`${server}/shop/reset-shop-password/${token}`, {
        newPassword: password,
        confirmPassword: password,
      });
      toast.success("Password updated successfully");
      navigate("/shop-login");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${server}/shop/login-shop`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Login Success!");
        navigate("/dashboard");
        window.location.reload(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      await axios.post(`${server}/shop/forgot-shop-password`, { email });
      setResetPasswordSent(true);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check if token exists in URL params
    if (token) {
      // If token exists, show reset password form
      setShowResetForm(true);
    }
  }, [token]);
  return (
    <div>
      <Header activeHeading={1} />
      <div className="font-[sans-serif] justify-center sm:mx-auto sm:w-full sm:max-w-md bg-gradient-to-r from-fuchsia-300 to-slate-300 rounded-md ml-2 mr-2 mt-10 mb-10 p-2 text-black">
      <div className="  max-w-xl text-center justify-center">
      <h1 className="text-2xl font-extrabold text-black-600 p-4">
      SHOP LOGIN
        </h1>
      </div>
      <div className="max-w-xl justify-center">
        <div className="bg-white py-8 px-4 pb-4 shadow sm:rounded-lg sm:px-10">
          {showResetForm ? (
            <form className="space-y-6" onSubmit={handleResetPassword}>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className={`${styles.noramlFlex} justify-between`}>
                <div></div>
                <div className="text-sm">
                  <button
                    type="submit"
                    className={`${
                      loading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                    } group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white`}
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Reset Password"}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <form className="space-y-6" onSubmit={handleSubmit}>
              {/* email input */}
              <div></div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              {/* password input */}
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>
              <div className={`${styles.noramlFlex} justify-between`}>
                <div className={`${styles.noramlFlex}`}>
                  <input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  {resetPasswordSent ? (
                    <p className="font-medium text-blue-600">
                      Password reset link has been sent to your email
                    </p>
                  ) : (
                    <button
                      type="button"
                      onClick={handleForgotPassword}
                      className={`${
                        loading
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-blue-600 hover:bg-blue-700"
                      } group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white`}
                      disabled={loading}
                    >
                      {loading ? "Processing..." : "Forgot your password?"}
                    </button>
                  )}
                </div>
              </div>
              {/* submit button */}
              <div>
                <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Submit
                </button>
              </div>
              {/* sign up link */}
              <div className={`${styles.noramlFlex} w-full`}>
                <h4>Not have any account?</h4>
                <Link to="/create-shop" className="text-blue-600 pl-2">
                  Sign Up
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
    <Whatsapp />
      <Footer />
    </div>
  );
};

export default ShopLogin;
