import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import EventCard from "../components/Events/EventCard";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import Footer from "../components/Layout/Footer";
import styles from "../styles/styles";
import ProductCard from "../components/Route/ProductCard/ProductCard";

const EventsPage = () => {
  const { allProducts, isLoading } = useSelector((state) => state.products);

  useEffect(() => {
    document.title = 'Events - Aarya Computer Store';
  }, []);

  const filteredProducts = allProducts
    ? allProducts.filter((product) => {
        const today = new Date();
        const endDate = new Date(product.endDate);
        return product.endDate && endDate >= today;
      })
    : [];


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="bg-gray-100" style={{ minHeight: "100vh" }}>
          <Header activeHeading={4} />
          <div className="flex justify-center">
      <div className={`${styles.section}`}>
        <div className={`${styles.heading}`}>
          <div>

          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-1 md:gap-[25px] lg:grid-cols-2 lg:gap-[25px] xl:grid-cols-2 xl:gap-[30px] mb-12 border-0">
          {filteredProducts &&
                filteredProducts.map((product, index) => (
                  <EventCard data={product} key={index} />
                ))}
            </div>
            {filteredProducts && filteredProducts.length === 0 ? (
              <h1 className="text-center w-full pt-[50px] pb-[70px]  text-[14px] sm:text-[18px] font-[500]">
                No Events found !
              </h1>
            ) : null}
        </div>
        </div>
        </div>
        </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default EventsPage;
