import React from "react";
import { useNavigate } from "react-router-dom";
import { brandingData, categoriesData } from "../../../static/data";
import styles from "../../../styles/styles";


const Categories = () => {
  const navigate = useNavigate();

  const handleSubmit = (category, subcategory) => {
    if (subcategory) {
      navigate(`/category/${category.id}/${subcategory.id}`);
    } else {
      navigate(`/products?category=${category.title}`);
    }
  };

  const mytextStyle = {
    margin: '300px auto',
    textAlign: 'center',
    color: 'black',
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'Arial, Helvetica, sans-serif',
    
    //textShadow: '0 0px 0 #ccc, 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc, 0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc, 0 9px 0 #ccc, 0 10px 0 #ccc, 0 11px 0 #ccc, 0 20px 30px rgba(0, 0, 0, 0.5)'
 
};

  return (
    <>
      {/* Hide for smaller screens */}
      <div className={`block ${styles.section} py-2`}>
        <div
          className={`branding my-5 sm:flex sm:justify-between w-full shadow-xl bg-white p-5 rounded-md`}
        >
          {brandingData &&
            brandingData.map((i, index) => (
              <div className="flex items-center pb-5 sm:pb-0" key={index}>
                {i.icon}
                <div className="px-3">
                  <h3 className="font-bold text-sm md:text-base">{i.title}</h3>
                  <p className="text-xs md:text-sm">{i.Description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Categories section */}
      <div
        className={`${styles.section} rounded-lg mb-5 sm:block`}
        id="categories"
      >
        <div className="grid grid-cols-2 gap-[2px] shadow-xl sm:grid-cols-5 p-2">
          {categoriesData &&
            categoriesData.map((category) => (
              <div
                className="w-full h-[80px] flex items-center bg-fuchsia-200 hover:bg-purple-300 rounded-xl justify-center cursor-pointer overflow-hidden"
                key={category.id}
                onClick={() => handleSubmit(category, null)}
              >
                <h5 style={mytextStyle} className="px-1">
                  {category.title}
                </h5>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Categories;
