import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProductCard from "../ProductCard/ProductCard"; // Import ProductCard component
import { Link, useLocation } from "react-router-dom"; // Import useLocation hook
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";

const SearchPage = () => {
  useEffect(() => {
    document.title = 'Search - Aarya Computer Store';
  }, []);
  const [searchResults, setSearchResults] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTerm = queryParams.get("term");

  useEffect(() => {
    if (searchTerm && allProducts) {
      const formattedSearchTerm = searchTerm.replace(/\s+/g, "").toLowerCase();
      const filteredResults = allProducts.filter((product) =>
        product.name.toLowerCase().replace(/\s+/g, "").includes(formattedSearchTerm)
      );
      setSearchResults(filteredResults);
    }
  }, [searchTerm, allProducts]);

  return (
    <div>
      <Header />
      <br />
    <div className="w-full block">
      {/* Show search term and number of results */}
      {searchTerm && searchResults.length > 0 ? (
        <div className="ml-3 md:ml-10 mb-5">
          <div className="flex"><p className="font-[500]">Search Results for:&nbsp;</p><span>"{searchTerm}"</span></div>
          <div className="flex"><p className="font-[500]">Total Results:&nbsp;</p><span>{searchResults.length}</span></div>
        </div>
      ) : null}

      <div className="grid mx-3 md:mx-10 grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0">
        {searchResults.length > 0 ? (
          searchResults.map((product, index) => (
            <ProductCard key={index} data={product} />
          ))
        ) : (
          <Link to="/shop">
          <div className="font-[400] w-full text-[20px] ml-3  mt-10 mb-5">
          <p className="font-[400]">↠ No products found !</p> </div> </Link>
          
        )}
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default SearchPage;
