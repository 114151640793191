import React, { useState } from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import logo from "../../Assests/logo.png";
import axios from "axios";
import { server } from "../../server";
import aarya from "../../Assests/aarya.png";
const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [processing, setProcessing] = useState(false);
  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false); // Reset success state

    if (!email) {
      setError("Email address is required.");
      return;
    }

    setProcessing(true); // Set processing to true when form is submitted

    try {
      const response = await axios.post(`${server}/subscription/subscribe`, {
        email,
      });

      if (response.status === 201) {
        console.log("Subscription successful");
        setSuccess(true);
        // Clear the email input after successful submission
        setEmail("");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setError(
        error.response.data.error ||
          "Failed to subscribe. Please try again later."
      );
    }

    setProcessing(false); // Reset processing state after submission is done
  };

  return (
    <div className=" font-[sans-serif] bg-gradient-to-r from-fuchsia-300 to-slate-300 rounded-xl ml-2 mr-2 text-black">
      <div className="  max-w-3xl mx-auto text-center p-2 ">
        <h3 className="text-3xl pt-6 font-extrabold text-black-600 py-2">
          Newsletter
        </h3>
        <p className="text-sm mt-6">
          Subscribe to our newsletter and stay up to date with the latest news,
          updates, and exclusive offers. Get valuable insights. Join our
          community today!
        </p>
        <form onSubmit={handleSubmit}>
          <div className="max-w-lg mx-auto bg-gray-100 flex px-2 py-1 rounded-full text-left mt-10 border focus-within:border-gray-700">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full outline-none bg-transparent text-sm px-4 py-3"
              value={email}
              onChange={handleEmailChange}
            />
            <button
              type="submit"
              className="bg-fuchsia-700 hover:bg-gray-800 transition-all text-white font-semibold text-sm rounded-full px-8 py-3"
              disabled={processing}
            >
              Submit
            </button>
          </div>
          {processing && <p className="text-blue-800 pt-5">Processing...</p>}
          {error && <p className="text-red-800 pt-5">{error}</p>}
          {success && (
            <p className="text-blue-800 pt-5">Subscription successful!</p>
          )}
        </form>
      </div>
      <div className="bg-slate-100">
        <div className="max-w-full mx-auto">
          <div className="grid grid-cols-1 min-[800px]:grid-cols-3 justify-center items-center text-slate-700 pt-10">
            
            {/* Logo and About */}
            <div className="flex flex-col justify-center items-center gap-4 pb-10">
              <h1 className="text-[20px] text-black text-center font-[600]">
                COMPANY
              </h1>
              <div className="flex justify-center items-center">
                <img className="h-20 w-auto " src={aarya} alt="Footer" />
              </div>
              <p className="text-[16px] text-center text-black font-[600] text-gray-700">
               Aarya Computer Trading LLC
              </p>
            </div>

            {/* Quick Links */}
            
            <div className="flex flex-col justify-center items-center gap-4 pb-10">
              <h1 className="text-[20px] text-black text-center font-[600]">QUICK LINK</h1>
              <ul className="flex flex-col gap-2 text-center text-gray-700 text-black font-[500]">
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/products" onClick={handleClick}>
                    Products
                  </Link>
                </li>
                <li>
                  <Link to="/best-selling" onClick={handleClick}>
                    Best Selling
                  </Link>
                </li>
                <li>
                  <Link to="/aboutus" onClick={handleClick}>
                    About Us
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contact us */}
            <div className="flex flex-col justify-center items-center gap-4 pb-10">
              <h1 className="text-[20px] text-black text-center font-[600]">CONTACT US</h1>
              <div className="flex flex-col gap-5 text-center text-gray-700 text-black font-[500]">               
                <span>UAE</span>
                <span>+971547584609</span>
                <span>info@aaryacomputer.com</span>
              </div>              
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="flex items-center justify-center text-base text-center pb-5">
          <p>Copyright ©️2024 Aarya Tech. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
