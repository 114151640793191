import React from "react";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { navItems } from "../../static/data";


const mytextStyle = {
  color: 'black',
  fontSize: '18px',
  fontWeight: '600',
  fontFamily: 'Arial, Helvetica, sans-serif',
};



const Navbar = ({ active, setOpen }) => {

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setOpen(false);
  };

  return (
    <div className={`block 800px:${styles.noramlFlex}`}>
      {navItems &&
        navItems.map((i, index) => (
          <div style={mytextStyle} className="flex" key={index}>
            {" "}
            {/* Added key prop */}
            <Link
            onClick={handleClick}
              to={i.url}
              className={`${
                active === index + 1
                  ? "text-red-600"
                  : "text-black 800px:text-[#000]"
              } pb-[30px] 800px:pb-0 px-5 cursor-pointer}`}
            >
              {i.title}
            </Link>
          </div>
        ))}
    </div>
  );
};

export default Navbar;
