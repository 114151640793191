import React from "react";
import { useSelector } from "react-redux";
import EventCard from "./EventCard";
import styles from "../../styles/styles";


const Events = () => {
  const { allProducts, isLoading } = useSelector((state) => state.products);

  const filteredProducts = allProducts
    ? allProducts.filter((product) => {
        const today = new Date();
        const endDate = new Date(product.endDate);
        return product.endDate && endDate >= today;
      })
    : [];

  return (
    <div>
      <div className={`${styles.section}`}>
        <div className={`${styles.heading}`}>
      {!isLoading && (
        <div>
          <div>
            <h1 className="text-left">
              Popular Events
            </h1>
          </div>
          <div className="grid grid-cols-1 gap-[20px] md:grid-cols-1 md:gap-[25px] lg:grid-cols-2 lg:gap-[25px] xl:grid-cols-2 xl:gap-[30px] border-0">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((event, index) => (
                <EventCard key={index} data={event} />
              ))
            ) : (
              <div className="text-left text-[16px] font-[400] pt-5">
                <h4>No Events Available right now !!!</h4>
              </div>
            )}
          </div>
        </div>
      )}
      {/* {isLoading && <div className="text-center">Loading...</div>}
      <Footer /> */}
    </div>
    </div>
    </div>
  );
};

export default Events;
