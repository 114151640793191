import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllProductsShop } from "../../redux/actions/product";
import { updateProduct } from "../../redux/actions/product";


const CreateEvent = () => {
  const { seller } = useSelector((state) => state.seller);
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const { success, error } = useSelector((state) => state.events);
  const [processing, setProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false); // State variable to track update success

  const [images, setImages] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState("");

  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch, seller, allProducts ]);

  const handleStartDateChange = (e) => {
    const startDate = new Date(e.target.value);
    const minEndDate = new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000);
    setStartDate(startDate);
    setEndDate(null);
    document.getElementById("end-date").min = minEndDate
      .toISOString()
      .slice(0, 10);
  };

  const handleEndDateChange = (e) => {
    const endDate = new Date(e.target.value);
    setEndDate(endDate);
  };


  const today = new Date().toISOString().slice(0, 10);

  const minEndDate = startDate
    ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10)
    : "";
   
  // Function to handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();

  // Show processing message
  setIsUpdating(true);

  try {
    const newForm = new FormData();

    newForm.append("startDate", startDate);
    newForm.append("endDate", endDate);

    // Dispatch updateProduct action with FormData object
    await dispatch(updateProduct(selectedProduct, newForm));

    // Show success message after successful update
    toast.success("Product updated successfully!");
    navigate("/dashboard-events");
    window.location.reload();
    setUpdateSuccess(true); // Set update success to true
  } catch (error) {
    // Show error message if update fails
    toast.error("Failed to update product. Please try again later.");
  } finally {
    // Reset the updating state after navigating or updating fails
    setIsUpdating(false);
  }
};


  return (
    <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create Event</h5>
      {/* create event form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Select Product <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}  
            required
          >
            <option value="">Choose a product</option>
            {allProducts.map((product) => (
              <option key={product._id} value={product._id}>
                {product.name}
              </option>
            ))}
          </select>
        </div>
        <br />
        <br />
        <div>
          <label className="pb-2">
            Event Start Date <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="date"
            name="price"
            id="start-date"
            value={startDate ? startDate.toISOString().slice(0, 10) : ""}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={handleStartDateChange}
            min={today}
            placeholder="Enter your event product stock..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Event End Date <span className="text-red-500">*</span>
          </label>
          <input
          required
            type="date"
            name="price"
            id="start-date"
            value={endDate ? endDate.toISOString().slice(0, 10) : ""}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={handleEndDateChange}
            min={minEndDate}
            placeholder="Enter your event product stock..."
          />
        </div>
        <br />
        <div>
          <br />
          <div>
            {processing && <p>Processing...</p>}
            {!processing && (
              <input
              type="submit"
              value={processing ? "Processing..." : "Create"}
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              disabled={processing} // Disable the button while processing
            />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateEvent;
