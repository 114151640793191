import React, { useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const FAQPage = () => {
  return (
    <div>
      <Header activeHeading={5} />
      <Faq />
      <Footer />
    </div>
  );
};

const Faq = () => {
  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    document.title = 'FAQ - Aarya Computer Store';
  }, []);

  return (
    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">FAQ</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(2)}
          >
            <span className="text-lg font-medium text-gray-900 text-left">
              What is your return policy?
            </span>
            {activeTab === 2 ? (
              <AiOutlineMinus className="h-6 w-6 text-gray-500" />
            ) : (
              <AiOutlinePlus className="h-6 w-6 text-gray-500" />
            )}
          </button>
          {activeTab === 2 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                If you're not satisfied with your purchase, we accept returns
                within 15 days of delivery. To initiate a return, please email
                us at info@aaryacomputer.com with your order number(you can view it by visiting your profile then click on orders tab) and a
                brief explanation of why you're returning the item.
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(3)}
          >
            <span className="text-lg font-medium text-gray-900 text-left">
              How do I track my order?
            </span>
            {activeTab === 3 ? (
              <AiOutlineMinus className="h-6 w-6 text-gray-500" />
            ) : (
              <AiOutlinePlus className="h-6 w-6 text-gray-500" />
            )}
          
          </button>
          {activeTab === 3 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                You can track your order by clicking the track order tab which is located into your profile page on our website.
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(4)}
          >
            <span className="text-lg font-medium text-gray-900 text-left">
              How do I contact customer support?
            </span>
            {activeTab === 4 ? (
      
              <AiOutlineMinus className="h-6 w-6 text-gray-500" />
            ) : (
              <AiOutlinePlus className="h-6 w-6 text-gray-500" />
            )}
       
          </button>
          {activeTab === 4 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                You can contact our customer support team by emailing us at
                info@aaryacomputer.com, or by calling or whatsapp us at +971-547584609
                between the hours of 9AM and 8PM, Monday through Friday.
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(5)}
          >
            <span className="text-lg font-medium text-gray-900 text-left">
              Can I change or cancel my order?
            </span>
            {activeTab === 5 ? (
              <AiOutlineMinus className="h-6 w-6 text-gray-500" />
            ) : (
              <AiOutlinePlus className="h-6 w-6 text-gray-500" />
            )}
            
          </button>
          {activeTab === 5 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                Unfortunately, once an order has been placed, we are not able to
                make changes or cancellations. If you no longer want the items
                you've ordered, you can return them for a refund within 15 days
                of delivery.
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(6)}
          >
            <span className="text-lg font-medium text-gray-900 text-left">
              Do you offer international shipping?
            </span>
            {activeTab === 6 ? (
              <AiOutlineMinus className="h-6 w-6 text-gray-500" />
            ) : (
              <AiOutlinePlus className="h-6 w-6 text-gray-500" />
            )}
          </button>
          {activeTab === 6 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                Currently, we only offer shipping within the UAE.
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(7)}
          >
            <span className="text-lg font-medium text-gray-900 text-left">
              What payment methods do you accept?
            </span>
            {activeTab === 7 ? (
              <AiOutlineMinus className="h-6 w-6 text-gray-500" />
            ) : (
              <AiOutlinePlus className="h-6 w-6 text-gray-500" />
            )}
          </button>
          {activeTab === 7 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
                We accept any debit or credit card, paypal and also we have
                cash on delivery system.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
