import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import ProductCard from "../Route/ProductCard/ProductCard";
 
const SuggestedProduct = ({ data, reloadPage }) => {
  const { allProducts } = useSelector((state) => state.products);
  const [productData, setProductData] = useState([]);
 
  useEffect(() => {
    const d =
      allProducts && allProducts.filter((i) => i.category === data.category);
    setProductData(d);
  }, [allProducts, data.category]);
 
  const handleProductClick = () => {
    console.log("Product clicked");
    window.scrollTo({ top: 0, behavior: "smooth" }); 
    reloadPage(); 
  };
 
  return (
<div>
      {data && (
<div className={`p-4 ${styles.section}`}>
<h2
            className={`text-[25px] font-[500] border-b mb-5 font-[600]`}
>
            Related Product
</h2>
<div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
            {productData.map((product, index) => (
<ProductCard
                data={product}
                key={index}
                onClick={handleProductClick}
              />
            ))}
</div>
</div>
      )}
</div>
  );
};
 
export default SuggestedProduct;