import React, { useState } from "react";
import styles from "../../styles/styles";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

const Checkout = () => {
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [userInfo, setUserInfo] = useState(false);
  const [address1, setAddress1] = useState("");

  const [PhoneNo, setPhoneNo] = useState("");
  const [address2, setAddress2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [area, setArea] = useState("");

  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paymentSubmit = () => {
    if (
      address1 === "" ||
      address2 === "" ||
      landmark === "" ||
      PhoneNo === "" ||
      country === "" ||
      city === ""
     
    ) {
      toast.error("Please choose your delivery address!");
    } else {
      const shippingAddress = {
        address1,
        address2,
        landmark,
        PhoneNo,
        country,
        city,
        area,
     // Include phoneNumber in the shipping address
      };

      const orderData = {
        cart,
        totalPrice,
        subTotalPrice,
        discountPrice,
        shippingAddress, // Use shippingAddress object to carry all shipping information
        user,
      };

      // Assuming you're sending orderData to the seller dashboard
      // This is where you would typically send the data to the seller dashboard

      // update local storage with the updated orders array
      localStorage.setItem("latestOrder", JSON.stringify(orderData));
      navigate("/payment");
    }
  };

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = couponCode;

    await axios.get(`${server}/coupon/get-coupon-value/${name}`).then((res) => {
      const shopId = res.data.couponCode?.shopId;
      const couponCodeValue = res.data.couponCode?.value;
      if (res.data.couponCode !== null) {
        const isCouponValid =
          cart && cart.filter((item) => item.shopId === shopId);

        if (isCouponValid.length === 0) {
          toast.error("Coupon code is not valid for this shop");
          setCouponCode("");
        } else {
          const eligiblePrice = isCouponValid.reduce(
            (acc, item) => acc + item.qty * item.discountPrice,
            0
          );
          const discountPrice = (eligiblePrice * couponCodeValue) / 100;
          setDiscountPrice(discountPrice);
          setCouponCodeData(res.data.couponCode);
          setCouponCode("");
        }
      }
      if (res.data.couponCode === null) {
        toast.error("Coupon code doesn't exists!");
        setCouponCode("");
      }
    });
  };

  const discountPercentenge = couponCodeData ? discountPrice : "";

  const totalPrice = couponCodeData
    ? (subTotalPrice - discountPercentenge).toFixed(2)
    : (subTotalPrice).toFixed(2);

  console.log(discountPercentenge);

  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <ShippingInfo
            user={user}
            country={country}
            setCountry={setCountry}
            city={city}
            setCity={setCity}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            landmark={landmark}
            setLandmark={setLandmark}
            area={area}
            setArea={setArea}
            PhoneNo={PhoneNo}
            setPhoneNo={setPhoneNo}
          />
        </div>
        <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
          <CartData
            handleSubmit={handleSubmit}
            totalPrice={totalPrice}
            subTotalPrice={subTotalPrice}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            discountPercentenge={discountPercentenge}
          />
        </div>
      </div>
      <div
        className={`${styles.button} w-[150px] 800px:w-[280px] mt-10`}
        onClick={paymentSubmit}
      >
        <h5 className="text-white">Go to Payment</h5>
      </div>
    </div>
  );
};

const ShippingInfo = ({
  user,
  country,
  setCountry,
  city,
  setCity,
  userInfo,
  setUserInfo,
  address1,
  setAddress1,
  address2,
  setAddress2,
  setLandmark,
  landmark,
  PhoneNo,
  setPhoneNo,
  area,
  setArea,
}) => {
  return (
    <div className="w-full bg-white rounded-md p-5 pb-8">
      <h5 className="text-[18px] font-[500]">Shipping Address</h5>
      <br />
      <form>
        {/* Your existing form inputs */}

        <div className="w-full pb-3">
          <div className="w-[95%] md:flex justify-center items-center mb-5">
            <label className=" flex block w-[100%] md:w-[15%] mb-3 md:mb-0 font-[500]">Country<p className="text-red-500">&nbsp;*</p></label>
            <select
              className="w-[100%] md:w-[85%] border h-[40px] rounded-[5px]"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option className="block" value="">
                Choose your country
              </option>
              {Country &&
                Country.getAllCountries().map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-[95%] md:flex justify-center items-center mb-5">
            <label className="block flex w-[100%] md:w-[15%] mb-3 md:mb-0 font-[500]">City<p className="text-red-500">&nbsp;*</p></label>
            <select
              className="w-[100%] md:w-[85%] border h-[40px] rounded-[5px]"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <option className="block" value="">
                Choose your City
              </option>
              {State &&
                State.getStatesOfCountry(country).map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="w-[95%] md:flex justify-center items-center mb-5">
            <label className="block flex w-[100%] md:w-[25%] mb-3 md:mb-0 font-[500]">Full Address<p className="text-red-500">&nbsp;*</p></label>
            <input
              type="address"
              required
              value={address1}
              placeholder="Provides full address"
              onChange={(e) => setAddress1(e.target.value)}
              className={`${styles.input} w-[100%] md:w-[75%] border h-[40px] rounded-[5px]`}
            />
          </div>

          <div className="w-[95%] md:flex justify-center items-center mb-5">
            <label className="block w-[100%] flex md:w-[25%] mb-3 md:mb-0 font-[500]">Building & Flat<p className="text-red-500">&nbsp;*</p></label>
            <input
            required
              type="address"
              placeholder="e.g. Princess Tower, second floor, room No: 201"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              className={`${styles.input} w-[100%] md:w-[75%] border h-[40px] rounded-[5px]`}
            />
          </div>

          <div className="w-[95%] md:flex justify-center items-center mb-5">
            <label className="block flex w-[100%] md:w-[25%] mb-3 md:mb-0 font-[500]">Landmark<p className="text-red-500">&nbsp;*</p></label>
            <input
            required
              type="address"
              placeholder="e.g. Galeria Mall"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              className={`${styles.input} w-[100%] md:w-[75%] border h-[40px] rounded-[5px]`}
            />
          </div>

          <div className="w-[95%] md:flex justify-center items-center mb-5">
            <label className="block w-[100%] md:w-[25%] mb-3 md:mb-0 font-[500]">Area</label>
            <input
            required
              type="address"
              placeholder="e.g. business bay"
              value={area}
              onChange={(e) => setArea(e.target.value)}
              className={`${styles.input} w-[100%] md:w-[75%] border h-[40px] rounded-[5px]`}
            />
          </div>
          
          <div className="w-[95%] md:flex justify-center items-center mb-5">
            <label className="block flex w-[100%] md:w-[25%] mb-3 md:mb-0 font-[500]">Phone No.<p className="text-red-500">&nbsp;*</p></label>
            <input
            required
              type="number" // Change type to "tel" for phone number
              placeholder="Enter phone number"
              value={PhoneNo} // Assuming you have phoneNumber state
              onChange={(e) => setPhoneNo(e.target.value)} // Assuming you're using setState or similar for state management
              className={`${styles.input} w-[100%] md:w-[75%] border h-[40px] rounded-[5px]`}
            />
          </div>
       

        <div></div>
      </form>
      <h5
        className="text-[18px] cursor-pointer inline-block mb-3 md:mb-0 font-[500]"
        onClick={() => setUserInfo(!userInfo)}
      >
        Choose From saved address
      </h5>
      {userInfo && (
        <div>
          {user &&
            user.addresses.map((item, index) => (
              <div className="w-full flex mt-1">
                <input
                  type="checkbox"
                  className="mr-3"
                  value={item.addressType}
                  onClick={() =>
                    setAddress1(item.address1) ||
                    setAddress2(item.address2) ||
                    setPhoneNo(item.PhoneNo) ||
                    setCountry(item.country) ||
                    setLandmark(item.LandMark) ||
                    setArea(item.area) ||
                    setCity(item.city)
                  }
                />
                <h2>{item.addressType}</h2>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const CartData = ({
  handleSubmit,
  totalPrice,
  subTotalPrice,
  couponCode,
  setCouponCode,
  discountPercentenge,
}) => {
  return (
    <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Sub Total:</h3>
        <h5 className="text-[18px] font-[600]">AED  {subTotalPrice.toFixed(2)}</h5>
      </div>
      <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Shipping:</h3>
        <h5 className="text-[18px] font-[600]">Free</h5>
      </div>
      <br />
      <div className="flex justify-between border-b pb-3">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Discount:</h3>
        <h5 className="text-[18px] font-[600]">
          -{" "}
          {discountPercentenge ? "AED " + discountPercentenge.toString() : null}
        </h5>
      </div>
      <h5 className="text-[18px] font-[600] text-end pt-3">
        {" "}
        Total: AED  {subTotalPrice.toFixed(2)}
      </h5>
      <br />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className={`AED {styles.input} h-[40px] pl-2`}
          placeholder="Coupon code"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        <input
          className={`w-full h-[40px] border border-fuchsia-800 text-center text-black font-[600] rounded-[3px] mt-8 cursor-pointer`}
          value="Apply code"
          type="submit"
        />
      </form>
    </div>
  );
};

export default Checkout;
