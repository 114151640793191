import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import { server } from "../../server";
import axios from "axios";

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [imagesicon, setImagesicon] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [description1, setDescription1] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      navigate("/dashboard-products");
      window.location.reload();
      toast.success("Product created successfully!");
    }
  }, [dispatch, error, success]);

  const handleTagsChange = (e) => {
    setTags(e.target.value); // Update the tags state with the selected quality
  };
 
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const totalImages = images.length + files.length;
    setImages(files);
  
    if (totalImages <= 6) {
      files.forEach((file) => {
        const imageUrl = URL.createObjectURL(file);
        setImagesicon((old) => [
          ...old,
          { url: imageUrl, file }, // Include file object for each image
        ]);
      });
    } else {
      toast.error("You can only upload up to Six images.");
    }
  };
  
  const handleRemoveImage = (index, e) => {
    e.preventDefault(); // Prevent the default button click behavior
    setImages((old) => old.filter((_, idx) => idx !== index)); // Remove image from images state
    setImagesicon((old) => old.filter((_, idx) => idx !== index)); // Remove image from imagesicon state
  };
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const newForm = new FormData();

      images.forEach((image, index) => {
        newForm.append("images", image); // Use "images" as the field name for all images
      });

      newForm.append("name", name);
      newForm.append("description", description);
      newForm.append("description1", description1);
      newForm.append("category", category);
      newForm.append("subcategory", subcategory);
      newForm.append("tags", tags);
      newForm.append("originalPrice", originalPrice);
      newForm.append("discountPrice", discountPrice);
      newForm.append("stock", stock);
      newForm.append("shopId", seller._id);

      const response = await axios.post(
        `${server}/product/create-product`,
        newForm,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important to set the content type for file uploads
          },
        }
      )
    } finally {
      setProcessing(false);
      navigate("/dashboard-products");
      window.location.reload();
      // Set processing state to false regardless of success or failure
    }
  };

  return (
    <div className="w-[90%] 800px:w-[50%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create Product</h5>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <br />
        <div>
          <label className="pb-2">
            Name of Product <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your product name..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-xs"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your product description..."
          ></textarea>
        </div>
        <br />

        <div>
          <label className="pb-2">
            Description Details <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description1}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-xs"
            onChange={(e) => setDescription1(e.target.value)}
            placeholder="Enter your product description details..."
          ></textarea>
        </div>

        <br />
        <div>
          <label className="pb-2">
            Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Choose a category</option>
            {categoriesData.map((cat) => (
              <option value={cat.title} key={cat.id}>
                {cat.title}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Subcategory <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={subcategory}
            onChange={(e) => setSubcategory(e.target.value)}
          >
            <option value="">Choose a subcategory</option>
            {categoriesData
              .find((cat) => cat.title === category)
              ?.subcategories.map((subcat) => (
                <option value={subcat.title} key={subcat.id}>
                  {subcat.title}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2">Tags</label>
          <select
            name="tags"
            value={tags}
            onChange={handleTagsChange}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            <option value="" disabled>
              Select quality...
            </option>
            <option value="Good">Good</option>
            <option value="Average">Average</option>
            <option value="Excellent">Excellent</option>
            <option value="Brand-New">Brand New</option>
          </select>
        </div>

        <br />
        <div>
          <label className="pb-2">Original Price</label>
          <input
            type="number"
            name="price"
            value={originalPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder="Enter your product price..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="price"
            value={discountPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDiscountPrice(e.target.value)}
            placeholder="Enter your product price with discount..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Product Stock <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="price"
            value={stock}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStock(e.target.value)}
            placeholder="Enter your product stock..."
          />
        </div>
        <br />

        {/* Rest of your form fields */}
        {/* Your existing form fields */}
        <br />
        <div>
          <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {imagesicon &&
                imagesicon.map((img, index) => (
                  <div key={index} className="relative">
                    <img
                      src={img.url}
                      alt=""
                      className="h-[120px] w-[120px] object-cover m-2"
                    />
                    <button
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 cursor-pointer"
                    onClick={(e) => handleRemoveImage(index, e)} // Pass the event parameter
                  >
                    X
                  </button>
                  </div>
                ))}
            </div>
          <br />
          <div>
            <input
              type="submit"
              value={processing ? "Processing..." : "Create"}
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              disabled={processing} // Disable the button while processing
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProduct;
