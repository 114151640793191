import React from "react";
import styles from "../../styles/styles";
import apple from "./company/apple.png"
import dell from "./company/dell.png"
import hp from "./company/hp.png"
import samsung from "./company/samsung.png"
import shopping from "./company/shoppuing.png"
import jbl from "./company/jbl.png"
import microsoft from "./company/microsoft.png"

const Sponsored = () => {
  return (
    <div
      className={`${styles.section} bg-white py-10 px-5 mb-12 shadow-xl cursor-pointer rounded-xl`}
    >
      <div className="flex space-x-[40px] justify-between items-center overflow-x-auto">
        <div className="flex-none max-w-[80px] min-[800px]:max-w-[120px]"><img className="p-1 w-full" src= {dell} alt=""/></div>
        <div className="flex-none max-w-[80px] min-[800px]:max-w-[120px]"><img className="p-1 w-full" src= {apple} alt=""/></div>
        <div className="flex-none max-w-[200px] min-[800px]:max-w-[250px]"><img className="p-1 w-full" src= {microsoft} alt=""/></div>
        <div className="flex-none max-w-[80px] min-[800px]:max-w-[120px]"><img className="p-1 w-full" src= {shopping} alt=""/></div>
        <div className="flex-none max-w-[80px] min-[800px]:max-w-[120px]"><img className="p-1 w-full" src= {hp} alt=""/></div>
        <div className="flex-none max-w-[80px] min-[800px]:max-w-[120px]"><img className="p-1 w-full" src= {jbl} alt=""/></div>
        <div className="flex-none max-w-[150px] min-[800px]:max-w-[200px]"><img className="p-1 w-full rounded-lx" src= {samsung} alt=""/></div>
      </div>
    </div>
  );
};

export default Sponsored;
