import React from "react";
import Body from "./body/bodymain.jsx";
import Services from "./serv/Services.jsx";
import Feat from "./feat/feature.jsx";
import Footer from "./Footer/footermain.jsx";
import AboutUs from "./about/about.jsx";
import ChatBox from "./chat/whatsappmain.jsx";
import Client from "./Testimonial/client.jsx";
import Subscription from "./subscription/subscriptionPage.jsx";
import Scroll from './scroll/scrollmain.jsx';
import Navbar from "./Navbar/navbarmain.jsx";
// import Cont from "./Cont/Cont.jsx";

const HomePage = () => {
  return (
    <>
   
    <div>
      <Navbar/>
      <Body />
      <Services />
      <Feat />
      <Scroll />
      <ChatBox />
     <Footer/>
    </div>
 
    </>
  );
};
export default HomePage;
