import React, { useState, useEffect, useRef } from "react";
import Whatsapp from "../components/Whatsapp/whatsapp";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import about1 from "./picture/about1.jpg"
import about2 from "./picture/about2.png"

const AboutUs = () => {
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const wordsLimit = 100; // Define the number of words to display initially
  const paragraphRef = useRef(null);

  useEffect(() => {
    if (paragraphRef.current) {
      const text = paragraphRef.current.innerText;
      const words = text.split(" ");
      setShowButton(words.length > wordsLimit);
    }
  }, []);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    document.title = 'About Us - Aarya Computer Store';
  }, []);

  return (
    <>
      <Header />
      <br />
      <section className="ml-auto font-[sans-serif] mr-auto mb-4 p-5 flex items-center justify-center shadow-sm shadow-slate-600 rounded-3xl max-w-[1440px] bg-slate">
        <div className="justify-center flex-1 max-w-6xl mb-2 mx-auto md:px-6 animate-fade-up animate-once">
          <div className="text-center mb-8">
            <h1 className="text-3xl sm:text-4xl font-bold">
              About <span className="text-fuchsia-600">Us</span>
            </h1>
          </div>
          <div className="flex flex-wrap">
          <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
              <div className="relative">
                <img src={about2} alt="" className="object-cover rounded-xl" />
                <div className="absolute z-50 hidden lg:block w-full bottom-0 bg-fuchsia-300 rounded-bl-[80px] rounded"></div>
              </div>
              {expanded && (
                <div className="relative mt-4 hidden lg:block">
                  <img
                    src={about1}
                    alt=""
                    className="object-cover rounded-xl"
                  />
                </div>
              )}
            </div>
            <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0 ">
              <div className="relative">
                <h1 className="pl-2 border-l-8 border-fuchsia-600 font-[500] text-xl font-[600]">
                  Welcome to  Aarya Computer Store - Your Conscious Choice !!!
                </h1>
              </div>
              <p
                ref={paragraphRef}
                className="mt-6 mb-10 text-gray-700 text-[14px] sm:text-[16px] text-justify leading-7 text-gray-500"
              >
                Welcome to our Aarya Computer Store, your one-stop destination for a diverse range of high-quality
                 products in UAE. We bring together a community of passionate sellers who offer an array of 
                 items, including laptops, computers, smartphones, accessories and much more. 
                 Our mission is to create a marketplace that values both sellers and customers by promoting 
                 responsible and sustainable shopping practices. At the heart of our store is a commitment to 
                 conscious consumerism. We believe in supporting local businesses, providing a platform where 
                 sellers can reach a wider audience, and offering customers access to unique and quality products. 
                 Our goal is to foster a shopping environment that prioritizes ethical practices, fair pricing, 
                 and environmental sustainability.
                {showButton && !expanded && (
                  <>
                    {" "}
                    <br />
                    <button
                      onClick={toggleExpanded}
                      className="mt-2 text-white font-semibold rounded bg-transparent border px-3 py-1 hover:text-white transition-colors duration-300"
                      style={{
                        borderRadius: "8px",
                        backgroundImage:
                          "linear-gradient(to right, fuchsia, #00035d)",
                        backgroundSize: "200% auto",
                        transition: "background-position 0.5s",
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundPosition = "right")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundPosition = "left")
                      }
                    >
                      See more
                    </button>
                  </>
                )}

                {expanded && (
                  <>
                  {" "}
                  <div className="max-w-xl mx-auto">
        <h2 className="pl-2 border-l-8 border-fuchsia-600 font-[500] text-xl text-black font-[600] mt-10">Why Choose Us?</h2>
        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Diverse Selection</h3>
            <p className="text-gray-600">Explore a wide variety of products across different categories, ensuring you find exactly what you need.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Quality Assurance</h3>
            <p className="text-gray-600">We partner with trusted sellers who adhere to high standards of quality and reliability.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Support Local</h3>
            <p className="text-gray-600">By shopping with us, you're supporting local entrepreneurs and contributing to the growth of UAE's economy.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Sustainable Practices</h3>
            <p className="text-gray-600">We encourage eco-friendly practices among our sellers, promoting products that are sustainable and environmentally friendly.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Customer Satisfaction</h3>
            <p className="text-gray-600">Your satisfaction is our top priority. We offer excellent customer service to ensure a smooth and enjoyable shopping experience.</p>
          </div>
        </div>
        <div className="relative mt-4 block lg:hidden">
                      <img
                        src={about1}
                        alt=""
                        className="object-cover rounded-xl"
                      />
        </div>
      </div>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div>
          <div>
            {/* Team members section can be added here */}
          </div>
        </div>
        <Whatsapp />
        <br />
        <Footer />
      </section>
    </>
  );
};

export default AboutUs;
