import React from "react";
import { Link } from "react-router-dom";
import bg from "./bg.png";

const Body = () => {
  const containerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`, // Combine gradient and image
    backgroundSize: "cover",
    backgroundPosition: "center center", // Position the image at the right bottom
    backgroundRepeat: "no-repeat",
    paddingTop: "1rem", // Add padding of 2rem to the top
    paddingBottom: "2rem", // Add padding of 2rem to the bottom
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    minHeight: "50vh", // Set minimum height to cover half of the viewport
    display: "flex", // Center content vertically and horizontally
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    clipPath: "polygon(0 0, 100% 0, 100% 90%, 95% 100%, 0% 100%)",
    marginTop: "1rem", // Add top margin of 2rem
    borderBottomLeftRadius: "50px"
  };

  const foldedImageStyle = {
    clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0% 100%)",
    transition: "all 0.3s ease-in-out",
    transformOrigin: "center",
    borderRadius: "20px",
    maxWidth: "70%",
    maxHeight: "100%",
  };
  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top
  };
  return (
    <div style={containerStyle}>
      <div className="font-[sans-serif] w-10/12 grid-cols-2 mx-auto overflow-x-hidden lg:grid md:py-10 lg:py-24 xl:py-14 lg:mt-3 xl:mt-5">
        <div className="flex flex-col items-start md:mb-14 py-14 md:py-0 text-left">
          {/* Changed text-center to text-left */}
          <h1 className="text-3xl flex justify-start font-semibold font-[sans-serif] text-gray-200 xl:text-5xl lg:text-3xl whitespace-nowrap sm:flex-wrap">
            {/* Changed text-center to text-left */}
            <span className="block w-full">
              Aarya{" "}
              <span className="text-fuchsia-500">
                Computer <span className="hidden sm:inline">Trading L.L.C</span>
                <span className="sm:hidden block"  style={{ lineHeight: '1.8' }}>Trading L.L.C</span>
              </span>
            </span>{" "}
          </h1>

          <br />
          <h1 className="mt-2  xl:text-2xl text-white font-Roboto text-justify">
          We specialize in Trading of Electronics goods through our own e-commerce store, Website development and hosting,
	  Digital Marketing, Graphics Designing, App development, Business Email Setup and Many More.
          </h1>
          <div className="mt-6 flex justify-start">
            {/* Changed to flex and justify-start */}
            <Link
              to="/shop"
              onClick={handleClick}
              className="inline-block px-4 py-2 text-lg font-semibold tracking-wider text-white bg-red-500 rounded-md transition-colors duration-300 hover:bg-blue-600"
              style={{
                borderRadius: "8px",
                backgroundImage: "linear-gradient(to right, #0000FF , #FF0000)",
                backgroundSize: "200% auto",
                transition: "background-position 0.5s",
              }}
              onMouseEnter={(e) =>
                (e.target.style.backgroundPosition = "right")
              }
              onMouseLeave={(e) => (e.target.style.backgroundPosition = "left")}
            >
              Shop Now
            </Link>
          </div>
        </div>

        <Link
          to="/shop"
          className="pb-10 ml-auto overflow-hidden md:p-10 lg:p-0"
        >
          <img
            id="heroImg1"
            className="transition-all duration-300 ease-in-out hover:scale-105 lg:w-full sm:mx-auto sm:w-4/6 sm:pb-12 lg:pb-0"
            src="https://img.freepik.com/premium-photo/businessman-hand-touching-dartboard-with-arrow-setup-business-target-objective-goal-concept-by-3d-render-illustration_50039-3935.jpg"
            alt=""
            width="500"
            height="488"
            style={foldedImageStyle}
            onClick={handleClick}
          />
        </Link>
      </div>
    </div>
  );
};

export default Body;
