import React from "react";
import tally from "./tally.png";
import { FaTiktok, FaFacebookF, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top
  };

  return (
    <div className="">
      <div className="bg-white shadow-lg py-3 sm:py-5 mt-4 font-[sans-serif] rounded-t-[60px]">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 items-center text-slate-700">
            <div className=" flex justify-center items-center pt-3 pb-2">
              <img className="h-20 w-auto" src={tally} alt="Footer" />
            </div>
            <h1 className="text-xl text-center font-[sans-serif] font-bold">
              Tally Prime - Silver Partner
            </h1>
            <p className="leading-6 text-sm sm:text-[14px] text-center text-black font-semibold">
              "We also sell Tally Prime software with its customized features."
            </p>
          <div className="flex justify-center pt-5">
            <h1 className="text-[#191b1b] font-bold text-lg sm:text-xl">
              Follow Us
            </h1>
            </div>
            <div className="flex justify-center pt-3">
              <ul className="flex gap-2 sm:gap-4">
                <li className="h-10 w-10 bg-gray-400 hover:bg-gray-800 flex items-center justify-center text-white rounded-full text-lg">
                  <a target="_blank"
                    href="https://www.facebook.com/profile.php?id=61550883064430"
                    onClick={handleClick}
                  >
                    <FaFacebookF />
                  </a>
                </li>
                <li className="h-10 w-10 bg-gray-400 hover:bg-gray-800 flex items-center justify-center text-white rounded-full text-lg">
                  <a target="_blank"
                    href="https://www.instagram.com/aaryacomputertradingllc/"
                    onClick={handleClick}
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li className="h-10 w-10 bg-gray-400 hover:bg-gray-800 flex items-center justify-center text-white rounded-full text-lg">
                  <a target="_blank"
                    href="https://www.tiktok.com/@aaryamobiletrading01?_t=8kFpICnMJes&_r=1"
                    onClick={handleClick}
                  >
                    <FaTiktok />
                  </a>
                </li>
              </ul>
            </div>
          
        </div>
        {/* Footer Bottom */}
        <div className="mt-6 sm:mt-8 flex items-center justify-center text-red text-sm sm:text-base">
          <p>Copyright ©️2024 Conscious Tech. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
