import {React, useEffect} from "react";
import CheckoutSteps from '../components/Checkout/CheckoutSteps'
import Footer from '../components/Layout/Footer'
import Header from '../components/Layout/Header'
import Payment from "../components/Payment/Payment";

const PaymentPage = () => {
  useEffect(() => {
    document.title = 'Payment - Aarya Computer Store';
  }, []);

  return (
    <div className='w-full min-h-screen bg-[#f6f9fc]'>
       <Header />
       <Payment />
    </div>
  )
}

export default PaymentPage