import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  cart: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
};

// Action creators
export const addTocart = (data) => async (dispatch, getState) => {
  const existingItem = getState().cart.cart.find((item) => item._id === data._id);
  
  if (existingItem) {
    const updatedItem = { ...existingItem, qty: existingItem.qty + 1 };
    dispatch({
      type: "updateCart",
      payload: updatedItem,
    });
  } else {
    dispatch({
      type: "addToCart",
      payload: { ...data, qty: 1 },
    });
  }

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
};

// Reducer
export const cartReducer = createReducer(initialState, {
  addToCart: (state, action) => {
    const newItem = action.payload;
    return {
      ...state,
      cart: [...state.cart, newItem],
    };
  },

  updateCart: (state, action) => {
    const updatedItem = action.payload;
    const { _id, qty } = updatedItem;
    const updatedCart = state.cart.map(item =>
      item._id === _id ? { ...item, qty } : item
    );
    return {
      ...state,
      cart: updatedCart,
    };
  },

  removeFromCart: (state, action) => {
    const itemId = action.payload;
    return {
      ...state,
      cart: state.cart.filter(item => item._id !== itemId),
    };
  },

  // Quantity change handler
  updateCartItemQuantity: (state, action) => {
    const updatedItem = action.payload;
    const { _id, qty } = updatedItem;
    const updatedCart = state.cart.map(item =>
      item._id === _id ? { ...item, qty } : item
    );
    return {
      ...state,
      cart: updatedCart,
    };
  },
});
